
export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const GET_ALL_CATEGORIES = 'GET_ALL_CATEGORIES';
export const GET_CATEGORY_DETAILS = 'GET_CATEGORY_DETAILS';
export const GET_PARENT_CATEGORIES = 'GET_PARENT_CATEGORIES';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const ADD_SUB_CATEGORY = 'ADD_SUB_CATEGORY';
export const UPDATE_SUB_CATEGORY = 'UPDATE_SUB_CATEGORY';
export const DELETE_SUB_CATEGORY = 'DELETE_SUB_CATEGORY';

export const CATEGORY_START_LOADING = 'CATEGORY_START_LOADING';
export const CATEGORY_STOP_LOADING = 'CATEGORY_STOP_LOADING';
