export const GET_APPOINTMENT_CONFIGURATION = 'GET_APPOINTMENT_CONFIGURATION';
export const GET_ALL_APPOINTMENTS = 'GET_ALL_APPOINTMENTS';
export const GET_ALL_APPOINTMENTS_COUNTS = 'GET_ALL_APPOINTMENTS_COUNTS';
export const GET_CURRENT_WEEK_APPOINTMENTS = 'GET_CURRENT_WEEK_APPOINTMENTS';
export const APPOINTMENT_LOADING = 'APPOINTMENT_LOADING';
export const APPOINTMENT_STOP_LOADING = 'APPOINTMENT_STOP_LOADING';



export const UPDATE_APPOINTMENT_STATUS = 'UPDATE_APPOINTMENT_STATUS';



