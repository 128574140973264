export const categories = [
    "General",
    "Manufacturers",
    "Doctors",
    "Restaurants",
    "Automobiles",
    "Hospitals",
    "InteriorDesign",
    "RealEstate",
    "Boutique",
    "Hotel",
    "Education",
    "Electronics",
    "Spa",
    "Logistics",
    "Schools",
    "KinderGarden",
    "Pubs",
    "Traders",
    "ExportsNImports",
    "Saloon",
    "SwimmingPools",
    "DigitalMarketing",
    "Marketing",
    "Institutes",
    "Other"
];