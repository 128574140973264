import { Button, Card, IconButton, Stack } from "@mui/material";
import { Checkbox, FormControlLabel } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import "./Profile.css";
import Popup from "../common/Popup";
import { useEffect, useState } from "react";
import { uploadBusinessImagesAction } from "../../Redux/Actions/BusinessActions/business.actions";
import { ACTIVE_BUSINESS_ID } from "../../utils/constants";
import { useDispatch } from "react-redux";

const ImagesTab = ({ profile, setProfile }: any) => {
    const [previewData, setPreviewData] = useState({
            logoImage: profile?.logo?.logoUrl || null,
            featuredImage: profile?.featuredImage?.featuredUrl || null,
            bannerImage: profile.isBannerFeaturedImage ? null : profile.bannerImage?.bannerUrl,
            isBannerFeaturedImage: profile.isBannerFeaturedImage
    });
    const dispatch = useDispatch();

    const handleImageChange = (event: any, field: any) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setPreviewData({ ...previewData, [field]: reader.result });
            };
            reader.readAsDataURL(file);
        }
    };
    const handleDeleteImage = (field: any) => {
        setPreviewData({ ...previewData, [field]: "" });
    };
    const [childrenPopup, setChildrenPopup] = useState([]);

    useEffect(()=>{
        console.log(profile)
        setPreviewData({
            logoImage: profile?.logo?.logoUrl || null,
            featuredImage: profile?.featuredImage?.featuredUrl || null,
            bannerImage: profile.isBannerFeaturedImage ? null : profile.bannerImage?.bannerUrl,
            isBannerFeaturedImage: profile.isBannerFeaturedImage
    })
    }, [profile]);


    const getPreviewContent = (label: string, url: any) => {
        return (
            <div>
                <div>
                    <h2>{label}</h2>
                </div>
                <div>
                    <img src={url} alt={label} height="400px" width="400px" />
                </div>
            </div>
        )
    }

    const handlePreview = (type: string) => {
        let data: any = [];
        switch (type) {
            case "Logo Image":
                data.push(getPreviewContent("Logo Image", previewData.logoImage));
                break;
            case "Featured Image":
                data.push(getPreviewContent("Featured Image", previewData?.featuredImage));
                break;
            case "Banner Image":
                data.push(getPreviewContent("Banner Image", previewData.bannerImage));
                break;
            default:
        }

        setChildrenPopup(data);
        setIsPreviewPopupOpen(true);

    }

    const handleUpdateProfile = async () => {
        let businessId = localStorage.getItem(ACTIVE_BUSINESS_ID);
            if (previewData.logoImage !== profile.logo?.logoUrl) {
                dispatch(uploadBusinessImagesAction(businessId, {logoContent: previewData.logoImage}, "LOGO_IMAGE") as any);
            }
            if (previewData.featuredImage !== profile.featuredImage?.featuredUrl) {
                dispatch(uploadBusinessImagesAction(businessId, {featuredContent: previewData.featuredImage}, "FEATURED_IMAGE") as any);
            }
            if (!previewData.isBannerFeaturedImage && previewData.bannerImage !== profile.bannerImage?.bannerUrl) {
                dispatch(uploadBusinessImagesAction(businessId, {bannerContent: previewData.bannerImage}, "BANNER_IMAGE") as any);
            };
    }

    const [isPreviewPopupOpen, setIsPreviewPopupOpen] = useState(false);

    const popupActions = [{ label: 'Close', onClick: () => setIsPreviewPopupOpen(false), className: 'mr-2', variant: 'outlined', display: "none" }]

    return (
        <div className="w-full " style={{ fontFamily: "source Sans pro" }}>
            <div className=" bg-white p-4 h-[680px] shadow-md w-full" style={{ borderBottomLeftRadius: "15px", borderBottomRightRadius: "15px" }}>
                <Stack width={"100%"} style={{}}>
                    <Card sx={{  padding: "2%", height:"560px" }}>
                        
                        <div className="w-full" style={{ alignItems: "center", justifyContent:"space-between", display:"flex", marginTop:"5%" }}>

                            <div style={{ width: "100%", height: "60%" }}>
                                <h2 className="text-xl font-semibold mb-4">Logo</h2>

                                <div className="containerr" style={{ width: "400px", height: "300px", justifyContent: "center" }}>
                                    <img src={previewData?.logoImage || "https://placehold.co/600x400.png?text=No+Logo"} alt="Avatar" className="image" style={{ width: "100%" }} />
                                    <div className="middle">
                                        <div style={{ flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <IconButton component="label" sx={{ color: "rgba(89, 50, 234, 1)" }}>
                                                <EditIcon fontSize="large" sx={{ color: "rgba(89, 50, 234, 1)" }} />
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    hidden
                                                    onChange={(e) => handleImageChange(e, "logoImage")}
                                                />
                                            </IconButton>
                                            <IconButton
                                                sx={{ color: "rgba(89, 50, 234, 1)" }}
                                                onClick={() => handlePreview("Logo Image")}
                                                disabled={!previewData.logoImage}
                                            >
                                                <VisibilityIcon fontSize="large" />
                                            </IconButton>

                                            <IconButton
                                                sx={{ color: "rgba(89, 50, 234, 1)" }}
                                                onClick={() => handleDeleteImage("logoImage")}
                                                disabled={!previewData.logoImage}
                                            >
                                                <DeleteIcon fontSize="large" />
                                            </IconButton>
                                        </div>


                                    </div>
                                </div>
                            </div>

                            {/* Banner Image Section */}
                            <div style={{ width: "100%", height: "60%" }}>
                                <h2 className="text-xl font-semibold mb-4">Featured Image</h2>

                                <div className="containerr" style={{ width: "400px", height: "300px" }}>
                                    <img src={previewData.featuredImage || "https://placehold.co/600x400.png?text=No+Banner"} alt="Avatar" className="image" style={{ width: "100%" }} />
                                    <div className="middle">
                                        <div style={{ flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <IconButton component="label" sx={{ color: "rgba(89, 50, 234, 1)" }}>
                                                <EditIcon fontSize="large" sx={{ color: "rgba(89, 50, 234, 1)" }} />
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    hidden
                                                    onChange={(e) => handleImageChange(e, "featuredImage")}
                                                />
                                            </IconButton>
                                            <IconButton
                                                sx={{ color: "rgba(89, 50, 234, 1)" }}
                                                onClick={() => handlePreview("Featured Image")}
                                                disabled={!previewData.featuredImage}
                                            >
                                                <VisibilityIcon fontSize="large" />
                                            </IconButton>

                                            <IconButton
                                                sx={{ color: "rgba(89, 50, 234, 1)" }}
                                                onClick={() => handleDeleteImage("featuredImage")}
                                                disabled={!previewData.featuredImage}
                                            >
                                                <DeleteIcon fontSize="large" />
                                            </IconButton>
                                        </div>


                                    </div>
                                </div>
                            </div>

                            {!previewData.isBannerFeaturedImage && (
                            <div style={{ width: "100%" }}>
                                <h2 className="text-xl font-semibold mb-4">Banner Image</h2>

                                <div className="containerr" style={{ width: "400px", height: "300px" }}>
                                    <img src={previewData.bannerImage || "https://placehold.co/600x200.png?text=No+Banner+Image"} alt="Avatar" className="image" style={{ width: "100%" }} />
                                    <div className="middle">
                                        <div style={{ flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            <IconButton component="label" sx={{ color: "rgba(89, 50, 234, 1)" }}>
                                                <EditIcon fontSize="large" sx={{ color: "rgba(89, 50, 234, 1)" }} />
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    hidden
                                                    onChange={(e) => handleImageChange(e, "bannerImage")}
                                                />
                                            </IconButton>
                                            <IconButton
                                                sx={{ color: "rgba(89, 50, 234, 1)" }}
                                                onClick={() => handlePreview("Banner Image")}
                                                disabled={!previewData.bannerImage}
                                            >
                                                <VisibilityIcon fontSize="large" />
                                            </IconButton>

                                            <IconButton
                                                sx={{ color: "rgba(89, 50, 234, 1)" }}
                                                onClick={() => handleDeleteImage("bannerImage")}
                                                disabled={!previewData.bannerImage}
                                            >
                                                <DeleteIcon fontSize="large" />
                                            </IconButton>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        )}
                        </div>
                    </Card>
                </Stack>
                <div style={{display:"flex", justifyContent:"space-between", marginTop: "2%"}}>
                    <Button variant='outlined' >
                        Reset
                    </Button>
                    <Button variant='contained' onClick={handleUpdateProfile} >
                        Update Profile
                    </Button>
                </div>
            </div>
            {isPreviewPopupOpen && <Popup
                header={`${"Logo"} Preview`}
                inputs={[]}
                buttons={popupActions}
                onClose={() => setIsPreviewPopupOpen(false)}
                formValues={null}
                setFormValues={null}
                handleInputChange={() => { }}
                children={childrenPopup}
            />}

        </div >)
};

export default ImagesTab;