export const popularFAQs = [
    {
        "question": "What products/services do you offer?",
        "answer": "We offer a wide range of products and services to meet your needs. Please visit our Products/Services page for detailed information on what we provide."
    },
    {
        "question": "What are your business hours?",
        "answer": "Our business hours are from Monday to Friday, 9:00 AM to 6:00 PM. We are closed on weekends and public holidays."
    },
    {
        "question": "Where are you located?",
        "answer": "We are located at [Your Address]. For directions and more details, please visit our Contact Us page."
    },
    {
        "question": "How can I contact customer support?",
        "answer": "You can contact our customer support team via phone at [Your Phone Number], email at [Your Email], or through our live chat on the website."
    },
    {
        "question": "What is your pricing structure?",
        "answer": "Our pricing varies depending on the product or service. Please check our Pricing page or contact our sales team for a detailed quote."
    },
    {
        "question": "Do you offer any discounts or promotions?",
        "answer": "Yes, we offer seasonal discounts and promotions. Please subscribe to our newsletter or follow us on social media to stay updated on our latest offers."
    },
    {
        "question": "How do I create an account?",
        "answer": "You can create an account by clicking the 'Sign Up' button on our website and filling in the required information."
    },
    {
        "question": "How can I update my account details?",
        "answer": "To update your account details, log in to your account and go to the 'Account Settings' section. From there, you can update your personal information."
    },
    {
        "question": "Do you have a referral program?",
        "answer": "Yes, we have a referral program. You can refer friends and family and earn rewards. Please check our Referral Program page for more details."
    },
    {
        "question": "How do I place an order?",
        "answer": "You can place an order directly through our website by adding items to your cart and proceeding to checkout. For assistance, please contact our customer service team."
    },
    {
        "question": "What payment methods do you accept?",
        "answer": "We accept all major credit cards, PayPal, and bank transfers. Please see our Payment Options page for more information."
    },
    {
        "question": "Can I modify or cancel my order?",
        "answer": "You can modify or cancel your order before it is processed. Please contact our support team as soon as possible with your order details."
    },
    {
        "question": "Do you offer customization options?",
        "answer": "Yes, we offer various customization options for certain products/services. Please contact us to discuss your specific requirements."
    },
    {
        "question": "How can I track my order?",
        "answer": "Once your order has been shipped, you will receive a tracking number via email. You can use this number to track your order on our website."
    },
    {
        "question": "What is your return/exchange policy?",
        "answer": "We accept returns and exchanges within 30 days of purchase, provided the item is in its original condition. Please visit our Return Policy page for more details."
    },
    {
        "question": "Do you offer warranties or guarantees?",
        "answer": "Yes, we offer a warranty on select products. Please refer to the product description for warranty details or contact us for more information."
    },
    {
        "question": "What should I do if I receive a damaged or incorrect item?",
        "answer": "If you receive a damaged or incorrect item, please contact our support team immediately with your order number and photos of the item. We will assist you with a replacement or refund."
    },
    {
        "question": "Do you provide installation or setup services?",
        "answer": "Yes, we offer installation and setup services for select products. Please contact us to check availability and pricing."
    },
    {
        "question": "Can I schedule a consultation or demo?",
        "answer": "Yes, we offer free consultations and product demos. Please visit our Consultation page to schedule an appointment."
    },
    {
        "question": "What shipping options are available?",
        "answer": "We offer standard, express, and next-day shipping options. Shipping times and costs will be calculated at checkout based on your location and chosen method."
    },
    {
        "question": "Do you ship internationally?",
        "answer": "Yes, we ship to most countries worldwide. Please check our Shipping Policy page for a list of supported countries and shipping rates."
    },
    {
        "question": "How much does shipping cost?",
        "answer": "Shipping costs vary based on your location, the weight of the package, and the shipping method selected. You can view shipping costs during checkout."
    },
    {
        "question": "What is your delivery time frame?",
        "answer": "Delivery times depend on your location and the shipping method chosen. Standard shipping usually takes 5-7 business days, while express options are faster."
    },
    {
        "question": "Can I change my shipping address after placing an order?",
        "answer": "You can change your shipping address before your order is shipped. Please contact our support team as soon as possible to update your information."
    },
    {
        "question": "How do I handle customs and duties for international orders?",
        "answer": "International orders may be subject to customs fees and import duties. These are the responsibility of the customer and are not included in the order total."
    },
    {
        "question": "How do I initiate a return or exchange?",
        "answer": "To initiate a return or exchange, please contact our support team with your order number and reason for return. We will provide you with a return authorization and instructions."
    },
    {
        "question": "What items are eligible for returns?",
        "answer": "Items must be in their original condition and packaging to be eligible for a return. Please refer to our Return Policy page for more details."
    },
    {
        "question": "How long does it take to process a refund?",
        "answer": "Refunds are processed within 7-10 business days after we receive the returned item. The refund will be credited to your original payment method."
    },
    {
        "question": "Will I be charged for return shipping?",
        "answer": "In most cases, the customer is responsible for return shipping costs. However, if the return is due to a mistake on our part, we will cover the shipping costs."
    },
    {
        "question": "Can I get a store credit instead of a refund?",
        "answer": "Yes, you can choose to receive store credit instead of a refund. Please let us know your preference when initiating the return."
    },
    {
        "question": "How can I reset my password?",
        "answer": "To reset your password, click the 'Forgot Password' link on the login page and follow the instructions. You will receive an email with a link to reset your password."
    },
    {
        "question": "How do I report a problem with my order?",
        "answer": "If you encounter any issues with your order, please contact our customer support team with your order number and a description of the problem. We will assist you promptly."
    },
    {
        "question": "What should I do if I haven’t received my order?",
        "answer": "If your order is delayed or missing, please contact our support team with your order number. We will investigate and provide an update as soon as possible."
    },
    {
        "question": "How do I leave feedback or a review?",
        "answer": "You can leave feedback or a review by visiting the product page and clicking the 'Write a Review' button. We appreciate your feedback!"
    },
    {
        "question": "Do you offer support in other languages?",
        "answer": "Currently, we offer support in [Languages]. Please let us know your preferred language when contacting customer support."
    },
    {
        "question": "Do you have a privacy policy?",
        "answer": "Yes, we take your privacy seriously. You can read our full privacy policy on our Privacy Policy page to learn how we handle your data."
    },
    {
        "question": "How do I subscribe/unsubscribe from your newsletter?",
        "answer": "You can subscribe to our newsletter by entering your email in the sign-up form on our website. To unsubscribe, click the 'Unsubscribe' link at the bottom of any newsletter email."
    },
    {
        "question": "Do you have a blog or resource center?",
        "answer": "Yes, we have a blog and resource center where you can find articles, guides, and tips related to our products and services. Visit our Blog page to learn more."
    },
    {
        "question": "Can I request a custom quote?",
        "answer": "Yes, for custom orders or bulk purchases, please contact our sales team with your requirements, and we will provide you with a custom quote."
    },
    {
        "question": "What should I do if I’m not satisfied with my purchase?",
        "answer": "Your satisfaction is our priority. If you're not happy with your purchase, please contact our support team within 30 days of receiving your order, and we will do our best to resolve the issue."
    }
]
