// actionTypes.js

export const GET_ALL_BUSINESSES_REQUEST = 'GET_ALL_BUSINESSES_REQUEST';
export const GET_ALL_BUSINESSES_SUCCESS = 'GET_ALL_BUSINESSES_SUCCESS';
export const GET_ALL_BUSINESSES_FAILURE = 'GET_ALL_BUSINESSES_FAILURE';

export const GET_BUSINESS_DETAILS_REQUEST = 'GET_BUSINESS_DETAILS_REQUEST';
export const GET_BUSINESS_DETAILS_SUCCESS = 'GET_BUSINESS_DETAILS_SUCCESS';
export const GET_BUSINESS_DETAILS_FAILURE = 'GET_BUSINESS_DETAILS_FAILURE';

// actionTypes.js

export const CREATE_BUSINESS_REQUEST = 'CREATE_BUSINESS_REQUEST';
export const CREATE_BUSINESS_SUCCESS = 'CREATE_BUSINESS_SUCCESS';
export const CREATE_BUSINESS_FAILURE = 'CREATE_BUSINESS_FAILURE';

export const UPDATE_BUSINESS_PROFILE = 'UPDATE_BUSINESS_PROFILE';
export const GET_ALL_BUSINESS_ADDRESSES = 'GET_ALL_BUSINESS_ADDRESSES';
export const DELETE_BUSINESS_ADDRESS = 'DELETE_BUSINESS_ADDRESS';
export const UPDATE_BUSINESS_ADDRESS = 'UPDATE_BUSINESS_ADDRESS';
export const CREATE_BUSINESS_ADDRESS = 'CREATE_BUSINESS_ADDRESS';

export const GET_ALL_BUSINESS_LINKS = 'GET_ALL_BUSINESS_LINKS';
export const DELETE_BUSINESS_LINK = 'DELETE_BUSINESS_LINK';
export const UPDATE_BUSINESS_LINK = 'UPDATE_BUSINESS_LINK';
export const CREATE_BUSINESS_LINK = 'CREATE_BUSINESS_LINK';

export const GET_ALL_BUSINESS_FAQS = 'GET_ALL_BUSINESS_FAQS';
export const DELETE_BUSINESS_FAQ = 'DELETE_BUSINESS_FAQ';
export const UPDATE_BUSINESS_FAQ = 'UPDATE_BUSINESS_FAQ';
export const CREATE_BUSINESS_FAQ = 'CREATE_BUSINESS_FAQ';

export const GET_USER_DETAILS = 'GET_USER_DETAILS';