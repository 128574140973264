export const populatTestimonials = [
  {
    "feedback": "The attention to detail and commitment to quality really stood out during the entire process. The team ensured that every aspect of the project was handled with care and precision. I am incredibly impressed with the final result, and I highly recommend their services to anyone.",
    "title": "Attention to detail and commitment to quality delivered an outstanding final product"
  },
  {
    "feedback": "The customer service experience was exceptional from start to finish. Every question I had was addressed promptly, and the team took extra care to explain every step along the way. The end result exceeded all of my expectations, and I couldn’t be happier with their work.",
    "title": "Exceptional customer service with prompt responses and clear communication throughout"
  },
  {
    "feedback": "I was amazed at how smoothly the entire process went. The team was professional, courteous, and incredibly efficient. They completed everything on time without compromising on the quality of their work. I would definitely work with them again and recommend them without hesitation.",
    "title": "A seamless process with professional, efficient, and high-quality service delivered on time"
  },
  {
    "feedback": "The team took the time to understand exactly what I needed and delivered a solution that was not only practical but also creative. They worked closely with me throughout the entire process and made sure I was happy with the results. I’m very pleased with the final outcome.",
    "title": "Creative and practical solutions tailored to my needs with exceptional collaboration"
  },
  {
    "feedback": "From the initial consultation to the final delivery, the team was nothing short of amazing. They communicated clearly, kept me updated on the progress, and ensured that everything was completed to the highest standards. I’m thrilled with the results and will definitely be coming back.",
    "title": "Clear communication, regular updates, and high-standard results from start to finish"
  },
  {
    "feedback": "Their commitment to customer satisfaction was evident from the very beginning. They listened to my needs, made helpful suggestions, and went above and beyond to make sure I was happy with the service. The quality of their work is unmatched, and I’m grateful for their hard work.",
    "title": "Unmatched quality and a commitment to customer satisfaction beyond expectations"
  },
  {
    "feedback": "I’ve worked with many companies in the past, but this one truly stands out for its professionalism and attention to detail. The team delivered exactly what I needed, on time and within budget. Their expertise and dedication really shine through in their work.",
    "title": "Professionalism and attention to detail led to an outstanding on-time and within-budget delivery"
  },
  {
    "feedback": "Working with this team was a great experience from start to finish. They were extremely organized, kept me in the loop throughout the project, and delivered a final product that exceeded all of my expectations. I would highly recommend them to anyone looking for top-notch service.",
    "title": "Organized and communicative team that delivered a final product exceeding all expectations"
  },
  {
    "feedback": "The level of service I received was exceptional. The team was friendly, knowledgeable, and incredibly responsive to all of my requests. They truly went above and beyond to ensure that I was happy with the final product. I will definitely be using their services again in the future.",
    "title": "Exceptional service from a friendly and responsive team that goes above and beyond"
  },
  {
    "feedback": "The team’s dedication to providing high-quality work is truly commendable. They paid attention to every little detail and were committed to ensuring that the final product met my expectations. Their expertise and professionalism made the entire process enjoyable and stress-free.",
    "title": "High-quality work with attention to detail and professionalism made the process stress-free"
  },
  {
    "feedback": "I was thoroughly impressed with the level of communication and transparency throughout the project. The team kept me informed at every step, ensuring there were no surprises. The final result was exactly what I envisioned, and I couldn’t be happier with their work.",
    "title": "Clear communication and transparency led to a final result that matched my vision perfectly"
  },
  {
    "feedback": "This company delivered beyond my expectations in every way. They were easy to work with, very knowledgeable, and dedicated to delivering the best possible results. I’m so glad I chose them for this project, and I look forward to working with them again in the future.",
    "title": "Exceeded expectations with a knowledgeable and dedicated team that delivers great results"
  },
  {
    "feedback": "I was very impressed with how the team handled my project from start to finish. They took the time to understand my needs and worked diligently to meet my expectations. The final product was delivered on time and was of the highest quality. I highly recommend them!",
    "title": "Impressive handling of the project with a focus on understanding my needs and delivering high quality"
  },
  {
    "feedback": "Their customer service is truly one of the best I’ve encountered. The team was always available to answer my questions and provided regular updates on the progress of my project. They delivered an outstanding final product, and I am extremely satisfied with their work.",
    "title": "Outstanding customer service and regular updates throughout the project"
  },
  {
    "feedback": "I was blown away by the professionalism and expertise of this team. They took the time to listen to my needs and provided thoughtful solutions that were both innovative and practical. The final product was exceptional, and I’m thrilled with the results.",
    "title": "Professional and expert team delivering innovative and practical solutions"
  },
  {
    "feedback": "I couldn’t be more pleased with the quality of service and the final product I received. The team worked quickly and efficiently, without compromising on quality, and kept me informed every step of the way. I would definitely work with them again in the future.",
    "title": "High-quality, efficient service with excellent communication throughout"
  },
  {
    "feedback": "The team was incredibly responsive and provided outstanding support throughout the entire process. They took the time to ensure that I was satisfied with every aspect of the project, and the final result was perfect. I couldn’t have asked for a better experience.",
    "title": "Incredibly responsive team providing outstanding support and a perfect final result"
  },
  {
    "feedback": "I am extremely impressed with how quickly and efficiently the team completed my project. They were very professional and ensured that everything was completed to my specifications. The final product exceeded all of my expectations, and I will definitely be using them again.",
    "title": "Quick, efficient, and professional service that exceeded all expectations"
  },
  {
    "feedback": "I had a fantastic experience working with this company. The team was knowledgeable, friendly, and committed to delivering high-quality results. They kept me updated on progress and ensured that the final product met my expectations. Highly recommend their services!",
    "title": "Knowledgeable, friendly team delivering high-quality results with constant updates"
  },
  {
    "feedback": "The professionalism and expertise of the team were apparent from the very beginning. They took the time to understand my project and delivered a solution that was perfectly tailored to my needs. The final product was excellent, and I couldn’t be happier with the outcome.",
    "title": "Professional and expert team delivering a perfectly tailored solution to meet my needs"
  },
  {
    "feedback": "I’m extremely satisfied with the service I received. The team was responsive, attentive, and worked tirelessly to ensure that everything was done to the highest standard. I was kept informed at every stage, and the final product was exactly what I had hoped for.",
    "title": "Responsive, attentive team delivering high-standard service and perfect final product"
  },
  {
    "feedback": "From the moment I started working with this company, I knew I was in good hands. They were incredibly organized, professional, and focused on delivering a high-quality product. The level of care and attention they put into their work is truly remarkable.",
    "title": "Organized, professional, and focused on delivering high-quality results with great care"
  },
  {
    "feedback": "The team was an absolute pleasure to work with. They were professional, communicative, and really cared about delivering a great final product. They exceeded my expectations in every way, and I will definitely be returning for future projects.",
    "title": "Pleasure to work with a professional, communicative team that exceeded expectations"
  },
  {
    "feedback": "I’m incredibly impressed with the level of service I received. The team was always available to answer questions and made the entire process smooth and stress-free. The final product was fantastic, and I couldn’t have asked for a better experience.",
    "title": "Fantastic experience with a supportive team that made the process stress-free"
  },
  {
    "feedback": "Their customer service is unparalleled. They were always available to answer questions, address concerns, and make sure I was completely satisfied with the progress and final product. I would highly recommend their services to anyone seeking top-tier quality.",
    "title": "Unparalleled customer service with constant support and top-tier quality delivery"
  },
  {
    "feedback": "I was thoroughly impressed by the professionalism and dedication of this team. They provided thoughtful recommendations and worked diligently to ensure the project was completed on time and within budget. The final result was exactly what I was hoping for.",
    "title": "Thoughtful recommendations and timely completion within budget resulting in perfect final product"
  },
  {
    "feedback": "The team went above and beyond to ensure that I was satisfied with the service. They took the time to understand my needs and worked closely with me throughout the process. The final product was of the highest quality, and I couldn’t be happier with the results.",
    "title": "Above and beyond service with close collaboration resulting in high-quality product"
  },
  {
    "feedback": "The level of care and dedication the team showed was remarkable. They took the time to listen to my feedback and made adjustments as needed to ensure I was happy with the final product. I’m extremely satisfied with their work and will definitely return for future projects.",
    "title": "Remarkable care and dedication to ensure client satisfaction with the final product"
  },
  {
    "feedback": "The entire process was handled with the utmost professionalism. The team communicated effectively, provided regular updates, and ensured that all of my requirements were met. I’m very happy with the final result and look forward to working with them again.",
    "title": "Professional team providing regular updates and meeting all requirements with great results"
  },
  {
    "feedback": "I was extremely pleased with the level of service I received. The team was responsive, knowledgeable, and worked hard to ensure that I was satisfied with every aspect of the project. The final product exceeded my expectations, and I’m thrilled with the outcome.",
    "title": "Responsive and knowledgeable team delivering a final product that exceeded expectations"
  },
  {
    "feedback": "This company is fantastic to work with! The team was attentive to every detail, and they went out of their way to make sure the project was completed to my satisfaction. I’m very happy with the results and will definitely be recommending their services.",
    "title": "Attentive team ensuring project satisfaction and delivering excellent results"
  },
  {
    "feedback": "The team was exceptional in every way. They communicated clearly, provided regular updates, and made sure I was happy with the progress. The final product was even better than I had hoped for, and I am very impressed with their work.",
    "title": "Clear communication and regular updates leading to an impressive final product"
  },
  {
    "feedback": "I was thoroughly impressed with the professionalism and expertise of the team. They took the time to understand my needs and provided a solution that was both practical and creative. The final result exceeded my expectations, and I highly recommend their services.",
    "title": "Professional and expert team providing practical and creative solutions that exceeded expectations"
  },
  {
    "feedback": "The level of service I received was outstanding. The team was very responsive and made sure I was involved in every step of the process. They delivered a high-quality product that met all of my expectations, and I couldn’t be more pleased with the results.",
    "title": "Outstanding service with responsive team delivering high-quality results"
  },
  {
    "feedback": "I had an amazing experience working with this team. They were professional, efficient, and incredibly organized. The project was completed on time, and the final product was exactly what I wanted. I highly recommend them for anyone looking for quality service.",
    "title": "Professional, efficient, and organized team delivering exactly what I wanted on time"
  },
  {
    "feedback": "The quality of the work delivered was exceptional. The team was attentive to my needs, provided regular updates, and made sure I was completely satisfied with the outcome. I couldn’t have asked for a better experience and will definitely use their services again.",
    "title": "Exceptional quality of work with regular updates and attentive service ensuring satisfaction"
  },
  {
    "feedback": "This team is absolutely fantastic! They were incredibly professional, responsive, and dedicated to delivering the best possible results. The entire process was seamless, and I’m very happy with the final product. I will definitely be working with them again.",
    "title": "Fantastic team delivering a seamless process with professional and responsive service"
  },
  {
    "feedback": "The attention to detail and commitment to customer satisfaction is truly commendable. The team was responsive, organized, and made sure that the project was completed to the highest standards. I’m extremely happy with the outcome and highly recommend their services.",
    "title": "Commendable attention to detail and customer satisfaction with high-standard project delivery"
  },
  {
    "feedback": "I’m so impressed with how smoothly the project went from start to finish. The team communicated clearly, met every deadline, and delivered a final product that exceeded all of my expectations. I’m thrilled with the outcome and will definitely use their services again.",
    "title": "Smooth project execution with clear communication and a final product exceeding expectations"
  },
  {
    "feedback": "The team provided an exceptional service that was personalized to my specific needs. They were attentive, professional, and kept me informed every step of the way. The final result was outstanding, and I’m very satisfied with the entire experience.",
    "title": "Exceptional and personalized service delivering outstanding results with attentive care"
  },
  {
    "feedback": "The team’s professionalism and attention to detail were second to none. They went above and beyond to ensure that I was satisfied with the final product. The quality of their work is outstanding, and I highly recommend them for any project.",
    "title": "Above and beyond service with outstanding quality of work and attention to detail"
  },
  {
    "feedback": "Working with this team was a pleasure from start to finish. They were professional, efficient, and committed to delivering a high-quality product. The entire process was smooth, and the final result exceeded all of my expectations. I would highly recommend them!",
    "title": "Professional, efficient, and committed team delivering a high-quality product and smooth process"
  },
  {
    "feedback": "The level of service I received was truly exceptional. The team was attentive to my needs, provided regular updates, and ensured that the project was completed to the highest standards. I am very pleased with the final product and highly recommend their services.",
    "title": "Exceptional service with regular updates and high-standard project completion"
  },
  {
    "feedback": "I was thoroughly impressed with the speed and quality of the service provided. The team worked efficiently to meet my deadline without sacrificing quality. The final product was exactly what I had hoped for, and I will definitely be working with them again.",
    "title": "Efficient team delivering high-quality work without sacrificing deadlines"
  },
  {
    "feedback": "The team exceeded my expectations in every way. They were responsive, professional, and delivered a high-quality product that perfectly matched my vision. The entire process was seamless, and I will definitely be returning for future projects.",
    "title": "Responsive and professional team delivering seamless high-quality work matching my vision"
  },
  {
    "feedback": "I am extremely satisfied with the service I received. The team was very professional, communicative, and dedicated to delivering the best possible results. The final product exceeded my expectations, and I highly recommend their services to anyone looking for quality work.",
    "title": "Professional, communicative, and dedicated team delivering high-quality work that exceeds expectations"
  }
]

  