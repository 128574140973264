
export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS';
export const GET_PRODUCT_DETAILS = 'GET_PRODUCT_DETAILS';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const ADD_PRODUCT_MEDIA = 'ADD_PRODUCT_MEDIA';
export const REMOVE_PRODUCT_MEDIA = 'REMOVE_PRODUCT_MEDIA';

export const PRODUCT_START_LOADING = 'PRODUCT_START_LOADING';
export const PRODUCT_STOP_LOADING = 'PRODUCT_STOP_LOADING';
