
export const CREATE_TESTIMONIAL = 'CREATE_TESTIMONIAL';
export const GET_ALL_TESTIMONIALS = 'GET_ALL_TESTIMONIALS';
export const GET_TESTIMONIAL_DETAILS = 'GET_TESTIMONIAL_DETAILS';
export const UPDATE_TESTIMONIAL = 'UPDATE_TESTIMONIAL';
export const DELETE_TESTIMONIAL = 'DELETE_TESTIMONIAL';
export const ADD_SUB_TESTIMONIAL = 'ADD_SUB_TESTIMONIAL';
export const UPDATE_SUB_TESTIMONIAL = 'UPDATE_SUB_TESTIMONIAL';
export const DELETE_SUB_TESTIMONIAL = 'DELETE_SUB_TESTIMONIAL';

export const TESTIMONIAL_START_LOADING = 'TESTIMONIAL_START_LOADING';
export const TESTIMONIAL_STOP_LOADING = 'TESTIMONIAL_STOP_LOADING';
